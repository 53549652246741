<template>
    <div class="navbarv3_wrap">
        <transition name="fade">
            <div :class="{nav_bar_wrap:true, scrolledOver, darkMode,}">
                <div :class="{
                    nav_bar:true,
                    nav_bar_fixed:true,
                    darkMode:darkMode,
                    isMobile:isMobile,
                    showing:(!scrolledOver && !showingMenu)
                }">
                    <div :class="{navbar_top_border:true, darkMode:darkMode}"></div>
                    <div :class="{left_piece:false, left_piece_mobile:isMobile}">
                        <NuxtLink to="" v-if="route.path=='/start'">
                                <span class="nav_bar_logo_text">
                                dataWANG
                                </span>
                        </NuxtLink>
                        <NuxtLink to="/start">
                            <div class="nav_bar_option clickable_dark">
                                <span class="nav_bar_logo_text">
                                    dataWANG
                                </span>
                            </div>
                        </NuxtLink>
                
                    </div>
                    <div class="announcement" v-if="(!scrolledOver)&& (menus||[]).filter(x=>!!x.display).length > 0 && !isMobile">
                        <div
                            v-for="(s,sind) in (menus||[]).filter(x=>!!x.display)"
                            :key="sind"
                            :class="{
                                announcement_child:true,
                                left_right_borders: s.display == '华美',
                                clickable_darker:true,
                                darkMode: darkMode,
                                currNode: s.display == currCategory1
                            }"
                            @click="onClickNode(s)"
                        >
                                {{s.display}}
                        </div>
                    </div>
                    
                        
                    <div class="announcement isMobile" 
                        v-if="isMobile && (navBarStore.sectionData ? navBarStore.sectionData.children : [] || []).length > 0"
                    >
                            <a-dropdown :trigger="['click']"
                                placement="bottom"
                            >      
                                <div class="mobile_nav_menu" @click="toggleMenu">
                                    <img :class="{multiply:true,menu_img:true, show:showMenu, hide:!showMenu}" src="https://huamei2001.oss-cn-shenzhen.aliyuncs.com/icons/multiply.png"/>
                                    <img :class="{menu_img:true, show:!showMenu, hide:showMenu}" src="https://huamei2001.oss-cn-shenzhen.aliyuncs.com/icons/menu_button.png"/>
                                </div>
                                <template #overlay>
                                    <a-menu class="drop_menu">
                                        <a-menu-item
                                         class="drop_menu_item"
                                            v-for="(s,sind) in menus"
                                            :key="sind"
                                            :class="{
                                                announcement_child:true, 
                                                clickable_dark:true,
                                                currNode:isCurrNode(s) 
                                            }"
                                            @click="onClickNode(s)"
                                        >
                                            {{s.display}}
                                        </a-menu-item>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                    </div>
                
                    <div class="right_piece"  v-if="!isMobile">
                        <div class="nav_bar_option hoverable"  v-if="userInfoStore.info">
                            <a-dropdown :trigger="['click']"
                                placement="bottomRight"
                            >      
                                <div class="profile_area" v-if="userInfoStore.info">
                                    <div :class="{exit:true, darkMode: darkMode}"
                                            @click.stop="onClickLogOut">
                                        <img 
                                        class="ad_icon"
                                        src="https://img.js.design/assets/element/js_1vUKQOHLOrA/image/7f6b9b12a44b5b6baf50865732d2bd48550d09f4.png" alt="">
                                    </div>

                                    <div class="profile_greeting">
                                        你好，用户A
                                    </div>
                                </div>
                                <template #overlay>
                                    <a-menu>
                                        <a-menu-item key="4"
                                            @click="onClickFeedback"
                                        >
                                            提交反馈
                                        </a-menu-item>
                                        <a-menu-divider />
                                        <a-menu-item key="4"
                                            @click="onClickLogOut"
                                        >
                                            退出
                                        </a-menu-item>
                                    </a-menu>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="nav_bar_option hoverable"  v-else
                            @click="clientLogin"
                        >
                            登录
                        </div>
                
                    </div>
                    <div :class="{navbar_bot_border:true, darkMode:darkMode}"></div>
                </div>
            </div>
        </transition>

        <div :class="{sandwich_menu:true, 
            showing:showSandwich && (scrolledOver || showingMenu)}"
        >   
            <div class="sandwich_nemu_content">
                <img src="https://huamei2001.oss-cn-shenzhen.aliyuncs.com/icons/menu.png" 
                    alt=""
                    :class="{nav_bar_logo_menucontrol:true, showing: !showingMenu}"
                    @click="onClickSandwichMenu"
                >
                <img src="https://huamei2001.oss-cn-shenzhen.aliyuncs.com/icons/multiply.png"
                    alt=""
                    :class="{nav_bar_logo_menucontrol:true, showing: showingMenu}"
                    @click="onClickSandwichMenu"
                >
                <span :class="{
                        page_title:true, 
                        truncate: (pageContentType == 'file') || (pageContentType == 'gallery' && galleryArticleTitle)
                    }"
                    v-if="pageTitle">
                    {{ pageTitle }}
                </span>
            </div>
            <div :class="{
                    page_subtitle:true, 
                    truncate: (pageContentType == 'file') || 
                            (pageContentType == 'gallery' && galleryArticleTitle)
                }" 
                v-if="showSubTitle && (pageSubtitle != pageTitle)">
                {{ pageSubtitle }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useSectionInstanceStore} from '~/stores/sectionInstanceStore'
const sectionInstanceStore = useSectionInstanceStore()
import {useTopLeftDisplayStore} from "~/stores/topLeftDisplayStore"
const topLeftDisplayStore = useTopLeftDisplayStore()

const props = defineProps([
    "pageSubtitle", 
    "showSandwich",
    "isHome",
    "allowEdit",
    "hideTitle",
    "currCategory1",
    "darkMode",
    "pageTitle",
    "showingMenu",
    "showHMLogo",
    "showSubTitle",
    "pageContentType",
    "galleryArticleTitle"
])

const emit = defineEmits([
    "toggleSandwichMenu",
    "clickNavNode"
])

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)


import {useUserInfoStore} from '~/stores/userInfoStore'
const userInfoStore = useUserInfoStore()
import {useCurrCustomerTypeStore} from '~/stores/currCustomerTypeStore'
const currCustomerTypeStore = useCurrCustomerTypeStore()

import {useNavBarStore} from '~/stores/navBarState' 
const navBarStore = useNavBarStore()

import { getMenuRoots } from "~/menus/menus";
const menus = ref(await getMenuRoots() as any[])

const scrolledOver = ref(false)
const onScroll = (e:any) => {
    if(window.scrollY > 100){
        scrolledOver.value = true
    }else{
        scrolledOver.value = false
    }
}
onMounted(()=>{
    window.addEventListener('scroll', onScroll)
})


// mobile nav button
const showMenu = ref(false)
const toggleMenu = () => {
    showMenu.value = !showMenu.value
}


// nav
const route = useRoute()
const isCurrNode = (node:any) => {
    return route.path.includes(node.route)
}
const router = useRouter()
const onClickNode = (fileNode:any) => {

    switch(fileNode.display){
        case "酒店":
            sectionInstanceStore.values.hotel = undefined
        case "城市":
            sectionInstanceStore.values.city = ""
        case "省份":
            sectionInstanceStore.values.province = ""
    }

    if(fileNode.url){
        router.push(fileNode.url)
        return
    }
    emit('clickNavNode', fileNode)
}

// feedback
import {modal} from '@tian-libs/modal'
import clientLogin from '~~/utils/auth/clientLogin'
const onClickFeedback = async () => {

    return
}

const currOrgStore = useCookie('currOrg') as {value:string}

const onClickLogOut = async () => {
    modal.alert('确定退出登录吗？', async ()=>{
        try{
            tFetch('/api/account/logout', {useFetch})
        }catch(e){}
        const jwtCookie = useCookie("jwt")
        jwtCookie.value = ""
        userInfoStore.logOut()
        currOrgStore.value = ""
        currCustomerTypeStore.logOut()
        router.push('/login')
    })
}

// edit

// sandwich button
const onClickSandwichMenu = () => {
    emit('toggleSandwichMenu')
}

</script>

<style scoped>

@media screen and (min-width: 1000px){

    .nav_bar_wrap{
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        /* 16.101 + 41.281 = 57.381 */
        transition: all ease-in-out 0.3s;
        padding: 19.322px 57.381px 0px 57.381px;
    }
    .nav_bar_wrap:not(.darkMode):not(.scrolledOver){
        background-color: white;
    }
    .nav_bar_wrap.scrolledOver{
        width: fit-content;
    }
    .nav_bar{
        /* color:#d4d4d4; */
        /* height: 50px; */
        width: 100%;
        display: flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-between;
        /* border-top: 0.797px solid black;
        border-bottom: 0.797px solid black; */
        padding: 10px 10px 10px 0px;
        height: 58px;
        transition: all ease-in-out 0.3s;
    }
    .nav_bar:not(.darkMode){
        background-color: white;
    }
    .navbar_top_border.dodge{
        --left-gap: 100px;
        position: absolute;
        top:20px;
        width: calc(100% - 120px - var(--left-gap));
        transform: translateX(var(--left-gap));
        height: 0.397px;
        background-color: black;
    }
    .navbar_top_border:not(.dodge){
        --left-gap: 0px;
        position: absolute;
        top:20px;
        width: calc(100% - 120px - var(--left-gap));
        transform: translateX(var(--left-gap));
        height: 0.397px;
        background-color: black;
    }
    .navbar_bot_border.dodge{
        --left-gap: 100px;
        position: absolute;
        bottom:0px;
        width: calc(100% - 120px - var(--left-gap));
        transform: translateX(var(--left-gap));
        height: 0.397px;
        background-color: black;
    }
    .navbar_bot_border:not(.dodge){
        --left-gap: 0px;
        position: absolute;
        bottom:0px;
        width: calc(100% - 120px - var(--left-gap));
        transform: translateX(var(--left-gap));
        height: 0.397px;
        background-color: black;
    }
    .navbar_top_border.darkMode{
        background-color: white;
    }
    .navbar_bot_border.darkMode{
        background-color: white;
    }
    .nav_bar:not(.showing){
        opacity: 0;
        pointer-events: none;
    }
    .nav_bar.showing{
        opacity: 1;
    }
    .nav_bar.isMobile{
        width: 100%;
        overflow: hidden;
    }
    .left_piece{
        display: flex;
        flex-direction: row;
        line-height: 50px;
        width:100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        overflow: visible;
    }
    .left_piece_mobile{
        width: calc(100% - 50px);
    }
    .left_piece_shadow{
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    
    
    .router-link-exact-active{
        /* color: gray; */
        display
        : none;
    }
    
    .nav_bar_logo{
        width:40px;
        object-fit: cover;
        /* margin:5px; */
        border-radius: 5px;
        position: relative;
        top: -0px;
        height: 80px;
        width: 80px;
        /* background-color: white; */
    
        z-index: 100;
        left: 0px;
    }
    .nav_bar_logo_text{
        font-size: 20px;
        color:black;
        font-weight: 300;
        font-style: italic;
    }
    
    .nav_bar_logo_menucontrol{
        width:40px;
        object-fit: cover;
        margin:5px;
        border-radius: 5px;
        position: absolute;
        transition: all ease-in-out 0.3s;
        cursor: pointer;
    }
    .nav_bar_logo_menucontrol:not(.showing){
        opacity:0;
        pointer-events: none;
    }
    .nav_bar_logo_menucontrol.showing{
        opacity:1;
    }
    
    
    .announcement{
        position: relative;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items: center;
        overflow: auto;
        overflow-y:hidden;
        line-height:50px;
        /* background-color: rgb(0, 0, 0); */
    }
    
    .announcement_child{
        /* margin:10px 0px; */
        white-space:nowrap;
        /* height: 24px; */
        line-height: 24px;
        padding: 0px 15px;
        margin: 0px 4px;
        text-align: center;
        
        height: 32.53px;
        font-size: 20px;
        font-weight: 300;
        color: black;
        line-height: 32.53px;
        border-radius: 16.265px;
        /* overflow-y:hidden; */
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        border-left: 1px solid transparent;
    }
    
    .announcement_child.darkMode{
        color: white;
    }
    .announcement_child.left_right_borders:not(.currNode){
        border-left: 1px solid black;
        /* border-right: 1px solid black; */
        border-radius: 0px;
    }
    .announcement_child.left_right_borders.darkMode:not(.currNode){
        border-left: 1px solid white;
        /* border-right: 1px solid white; */
        border-radius: 0px;
    }
    /* .announcement_child:hover, */
    .announcement_child.currNode{
        background-color: rgb(0, 0, 0);
        color: white;
    }
    /* .announcement_child.darkMode:hover, */
    .announcement_child.currNode.darkMode{
        background-color: rgb(33, 33, 33);
        color: white;
    }
    .announcement_child_text_highlighted,
    .announcement_child_text_default{
        transition: all ease-in-out 0.2s;
        height: 24px;
        text-align: center;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
    
    }
    
    .announcement_child_text_default{
        /* position: absolute; */
        /* top: 0px;
        left: 0px; */
        z-index: 1;
    }
    
    /* .announcement_child:not(:hover):not(.currNode) .announcement_child_text_highlighted{
        transform: translateY(100%);
    } */
    /* .announcement_child:not(.currNode):hover .announcement_child_text_default{
        transform: translateY(-100%);
    } */
    
    .announcement_child_text_highlighted{
        color: #fdc448;
        color: #fd4848;
        color:white;
        background-color: black;
    }
    
    
    .announcement::-webkit-scrollbar {
      width: 5px;
      height: 7px;
      background-color: #d3d3d3;
    }
    .announcement.isMobile{
        right: 5px;
        top: 0px;
        left: auto;
        transform: translate(0%, 0%);
    
        max-width:100vw;
        /* width:100px; */
        height: 50px;
        object-fit: cover;
        justify-content:center;
    }
    
    
    .drop_menu{
        width: 80vw;
    }
    .drop_menu_item{
        font-size: 24px;
        padding: 10px 0px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        width: 7px;
        background-color: rgba(250,252,255,1);
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        cursor: pointer;
        width: 7px;
        background: rgb(203, 203, 203);
        border-radius: 5px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
        background: rgb(163, 163, 163);
    }
    .currNode{
        /* border-bottom: #ddd 1px solid; */
        background-color: #ffffff3b;
    
    }
    
    .right_piece{
        display: flex;
        flex-direction:row-reverse;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        position: relative;
        /* right: 15px; */
        width:100px;
    }
    .nav_bar_option{
        line-height: 50px;
        border-radius: 5px;
        overflow: visible;
    }
    
    
    .profile_area{
        display: flex;
        flex-direction:row;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
    }
    .profile_greeting{
        margin-left: 20px
    }
    .contact_us{
        width: 140px;
        height: 32.53px;
        text-align: center;
        font-size: 20px;
        line-height: 32.53px;
        font-size: 20px;
        border-radius: 16.265px;
        font-weight: 300;
        padding: 0px 0px;
        position: relative;
        overflow: visible;
        white-space: nowrap;
    }
    .contact_us:not(.darkMode){
        color: white;
        background-color: black;
    }
    .contact_us.darkMode{
        color: black;
        background-color: white;
    }
    .exit{
        width: 40px;
        font-size: 14px;
        text-align: right;
    }
    .exit.darkMode{
        filter: invert()
    }
    .profile_pic, .ad_icon{
        width: 35px;
        height: 35px;
        object-fit: contain;
        background-color:#eee;
        border-radius:3px;
    }
    .ad_icon{
        background-color:transparent;
        margin: 0px 20px 0px 0px;
        margin-left: 10px;
    }
    .ad_icon:not(:last-child){
        margin: 0px 5px;
    }
    
    .edit_announcement{
        position:fixed;
        white-space:nowrap;
        top:80px;
        left:50%; 
        transform:translateX(-50%);
        z-index:100;
        background-color:rgba(163, 163, 163, 0.953);
        /* color: white; */
        padding:5px 10px;
        border-radius:5px;
    }
    
    
    
    /* mobile */
    .mobile_nav_menu{
        position:relative;
        /* right: 10px; */
        /* right: 15px; */
        /* top:50%; */
        /* transform:translateY(-50%); */
        opacity: 0.7;
        z-index: 1000;
        cursor: pointer;
        margin-right: 5px;
        width: 40px;
        height: 40px;
        margin-top:5px;
        padding:2px;
    }
    .menu_img{
        width: 100%;
        height: 100%;
        position:absolute;
        right:0px;
        top: 0px;
        object-fit: contain;
        transition:all ease-in-out 0.3s;
        filter: invert();
    }
    .menu_img.multiply{
        padding:2px;
    }
    .menu_img.hide{
        transform: scale(0.3) ;
        opacity:0;
    }
    
    .nav_bar_title{
        width: 100%;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* sandwich menu */
    .sandwich_menu{
        /* width: 50px; */
        /* height: 50px; */
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: fixed;
        top: 20px;
        left: 37.381px;
        z-index: 100;
        transition: all ease-in-out 0.3s;
        overflow: visible;
        flex-direction: column;
        align-items: flex-start;
    }
    .sandwich_menu:not(.showing){
        opacity: 0;
        pointer-events: none;
    }
    .sandwich_nemu_content{
        /* border: 1px solid gray; */
        border-right: none;
        border-radius: 27.5px;
        padding-left: 30px;
        padding-right: 150px;
        padding-top: 10px;
        padding-bottom: 10px;
        /* background-color: red; */
        position: relative;
        backdrop-filter: blur(45px) !important;
        /* backdrop-filter: invert() !important; */
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: flex-start;
    }
    .sandwich_nemu_content img{
        background: none;
    }
    
    .page_title{
        --font-size: 43px;
        position: relative;
        left: 50px;
        font-size: var(--font-size);
        height: var(--font-size);
        line-height: var(--font-size);
        white-space: nowrap;
        border-right: none;
        margin-left: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all ease-in-out 0.2s;
        width: 100%;
    }
    .page_title.truncate{
        width: calc(25vw - 50px);
    }

    .page_subtitle{
        /* border: 1px solid gray; */
        padding-left: 40px;
        padding-right: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;
        left: -10px;
        display:inline-block;
        flex-direction: row;
        align-items:center;
        justify-content: flex-start;
        font-size: 30px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: all ease-in-out 0.2s;
        margin-top: 50px;
    }
    .page_subtitle.truncate{
        width: calc(25vw + 20px);
        overflow: hidden;
    }

    .sandwich_menu:hover .page_title.truncate,
    .sandwich_menu:hover .page_subtitle.truncate{
        width: 100vw;
    }

    .contact_prompt{
        position: absolute;
        top: calc(100% + 20px);
        right: 0px;
        width: 170px;
        z-index: 100;
        background-color: rgba(24, 24, 24, 0.841);
        padding: 10px;
        border-radius: 5px;
        transition: all ease-in-out 0.3s;
        text-align: center;
    }
    .contact_us:not(:hover) .contact_prompt{
        opacity: 0;
        pointer-events: none;
    }
    .contact_us:hover .contact_prompt{
        opacity: 1;
    }
}
@media screen and (max-width: 1000px){
    
    .nav_bar_wrap{
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 10;
        width: 100vw;
        /* 16.101 + 41.281 = 57.381 */
        padding: 19.322px 15px 0px 15px;
    }
    .nav_bar{
        /* color:#d4d4d4; */
        /* height: 50px; */
        width: 100%;
        display: flex;
        flex-direction:row;
        align-items: center;
        justify-content: space-between;
        /* border-top: 0.797px solid black;
        border-bottom: 0.797px solid black; */
        padding: 0px 10px 0px 0px;
        height: 58px;
        transition: all ease-in-out 0.3s;
    }
    .navbar_top_border{
        --left-gap: 75px;
        position: absolute;
        top:20px;
        width: calc(100% - 40px - var(--left-gap));
        transform: translateX(var(--left-gap));
        height: 0.397px;
        background-color: black;
    }
    .navbar_bot_border{
        --left-gap: 85px;
        position: absolute;
        bottom:0px;
        width: calc(100% - 40px - var(--left-gap));
        transform: translateX(var(--left-gap));
        height: 0.397px;
        background-color: black;
    }
    .navbar_top_border.darkMode{
        background-color: white;
    }
    .navbar_bot_border.darkMode{
        background-color: white;
    }
    .nav_bar:not(.showing){
        opacity: 0;
        pointer-events: none;
    }
    .nav_bar.showing{
        opacity: 1;
    }
    .nav_bar.isMobile{
        width: 100%;
        overflow: hidden;
    }
    .left_piece{
        display: flex;
        flex-direction: row;
        line-height: 50px;
        width:100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        overflow: visible;
    }
    .left_piece_mobile{
        width: calc(100% - 50px);
    }
    .left_piece_shadow{
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    
    
    .router-link-exact-active{
        /* color: gray; */
        display
        : none;
    }
    
    .nav_bar_logo{
        width:40px;
        object-fit: cover;
        /* margin:5px; */
        border-radius: 5px;
        position: relative;
        top: -0px;
        height: 80px;
        width: 80px;
        /* background-color: white; */
    
        z-index: 100;
        left: 0px;
    }
    
    .nav_bar_logo_menucontrol{
        width:40px;
        object-fit: cover;
        margin:5px;
        border-radius: 5px;
        position: absolute;
        transition: all ease-in-out 0.3s;
        cursor: pointer;
    }
    .nav_bar_logo_menucontrol:not(.showing){
        opacity:0;
        pointer-events: none;
    }
    .nav_bar_logo_menucontrol.showing{
        opacity:1;
    }
    
    
    .announcement{
        position: relative;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items: center;
        overflow: auto;
        overflow-y:hidden;
        line-height:50px;
        /* background-color: rgb(0, 0, 0); */
        height: 100%;
    }
    .announcement::-webkit-scrollbar{
        width: 5px;
    }
    
    .announcement_child{
        /* margin:10px 0px; */
        white-space:nowrap;
        /* height: 24px; */
        line-height: 24px;
        padding: 0px 25px;
        margin: 0px 4px;
        text-align: center;
        
        height: 32.53px;
        font-size: 20px;
        font-weight: 300;
        color: black;
        line-height: 32.53px;
        border-radius: 16.265px;
        /* overflow-y:hidden; */
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        transition: all ease-in-out 0.2s;
    }
    
    .announcement_child.darkMode{
        color: white;
    }
    /* .announcement_child:hover, */
    .announcement_child.currNode{
        background-color: rgb(0, 0, 0);
        color: white;
    }
    /* .announcement_child.darkMode:hover, */
    .announcement_child.currNode.darkMode{
        background-color: rgb(33, 33, 33);
        color: white;
    }
    .announcement_child_text_highlighted,
    .announcement_child_text_default{
        transition: all ease-in-out 0.2s;
        height: 24px;
        text-align: center;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
    
    }
    
    .announcement_child_text_default{
        /* position: absolute; */
        /* top: 0px;
        left: 0px; */
        z-index: 1;
    }
    
    .announcement_child_text_highlighted{
        color: #fdc448;
        color: #fd4848;
        color:white;
        background-color: black;
    }
    
    
    .announcement::-webkit-scrollbar {
      width: 5px;
      height: 7px;
      background-color: #d3d3d3;
    }
    .announcement.isMobile{
        right: 5px;
        top: 0px;
        left: auto;
        transform: translate(0%, 0%);
    
        max-width:100vw;
        /* width:100px; */
        height: 50px;
        object-fit: cover;
        justify-content:center;
    }
    
    
    .drop_menu{
        width: 80vw;
    }
    .drop_menu_item{
        font-size: 24px;
        padding: 10px 0px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        width: 7px;
        background-color: rgba(250,252,255,1);
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        cursor: pointer;
        width: 7px;
        background: rgb(203, 203, 203);
        border-radius: 5px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
        background: rgb(163, 163, 163);
    }
    .currNode{
        /* border-bottom: #ddd 1px solid; */
        background-color: #ffffff3b;
    
    }
    
    .right_piece{
        display: flex;
        flex-direction:row-reverse;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        position: relative;
        /* right: 15px; */
        width:100px;
    }
    .nav_bar_option{
        line-height: 50px;
        border-radius: 5px;
        overflow: visible;
    }
    .profile_area{
        display: flex;
        flex-direction:row;
        align-items: center;
        justify-content: center;
    }
    .contact_us{
        width: 180px;
        height: 32.53px;
        text-align: center;
        font-size: 20px;
        line-height: 32.53px;
        border-radius: 16.265px;
        font-weight: 300;
        font-size: 20px;
        /* padding: 0px 25px; */
        display: none;
    }
    .contact_us:not(.darkMode){
        color: white;
        background-color: black;
    }
    .contact_us.darkMode{
        color: black;
        background-color: white;
    }
    .exit{
        width: 40px;
        font-size: 14px;
        text-align: right;
    }
    .exit.darkMode{
        filter: invert()
    }
    .profile_pic, .ad_icon{
        width: 35px;
        height: 35px;
        object-fit: contain;
        background-color:#eee;
        border-radius:3px;
    }
    .ad_icon{
        background-color:transparent;
        margin: 0px 20px 0px 0px;
        margin-left: 10px;
    }
    .ad_icon:not(:last-child){
        margin: 0px 5px;
    }
    
    .edit_announcement{
        position:fixed;
        white-space:nowrap;
        top:80px;
        left:50%; 
        transform:translateX(-50%);
        z-index:100;
        background-color:rgba(163, 163, 163, 0.953);
        /* color: white; */
        padding:5px 10px;
        border-radius:5px;
    }
    
    
    
    /* mobile */
    .mobile_nav_menu{
        position:relative;
        /* right: 10px; */
        /* right: 15px; */
        /* top:50%; */
        /* transform:translateY(-50%); */
        opacity: 0.7;
        z-index: 1000;
        cursor: pointer;
        margin-right: 5px;
        width: 40px;
        height: 40px;
        margin-top:5px;
        padding:2px;
    }
    .menu_img{
        width: 100%;
        height: 100%;
        position:absolute;
        right:0px;
        top: 0px;
        object-fit: contain;
        transition:all ease-in-out 0.3s;
        filter: invert();
    }
    .menu_img.multiply{
        padding:2px;
    }
    .menu_img.hide{
        transform: scale(0.3) ;
        opacity:0;
    }
    
    .nav_bar_title{
        width: 100%;
        max-width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    /* sandwich menu */
    .sandwich_menu{
        /* width: 50px; */
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: fixed;
        top: 20px;
        left: 10px;
        z-index: 100;
        transition: all ease-in-out 0.3s;
        overflow: visible;
    }
    .sandwich_menu:not(.showing){
        opacity: 0;
        pointer-events: none;
    }
    .sandwich_menu.showing{
    }
    .sandwich_menu:hover{
    }
    .sandwich_nemu_content{
        /* border: 1px solid gray; */
        border-right: none;
        border-radius: 27.5px;
        padding-left: 30px;
        padding-right: 150px;
        padding-top: 5px;
        padding-bottom: 5px;
        /* background-color: red; */
        position: relative;
        backdrop-filter: blur(10px) !important;
        /* backdrop-filter: invert() !important; */
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content: flex-start;
    }
    .sandwich_nemu_content img{
        background: none;
    }
    .page_title{
        --font-size: 43px;
        position: relative;
        width:fit-content;
        left: 50px;
        font-size: var(--font-size);
        height: var(--font-size);
        line-height: var(--font-size);
        white-space: nowrap;
        /* border: 1px solid gray; */
        border-right: none;
        border-radius: 0px 20px 20px 0px;
        margin-left: 20px;
    }
}


</style>
<style>
.drop_menu_item .ant-dropdown-menu-title-content{
    font-size: 20px !important;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
}
</style>