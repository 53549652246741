import { times } from 'lodash';
import {watch} from 'vue'

const defaultInfo = {
    hotel: '',
    city: '',
    province: '',
}

export const useSectionInstanceStore = definePiniaStore('sectionInstance', () => {

    let localInfo = defaultInfo
    try{
        localInfo = JSON.parse(process.client ? (localStorage.getItem('sectionInstance') || 'null' ): 'null') 
                            || defaultInfo;
    }catch(err){
        console.log("local sectionInstance error", err)
    }

    console.log('localStorage sectionInstance', localInfo); 
    
    const values = ref(defaultInfo);
    if(localInfo){
        values.value = localInfo
    }
    watch(()=>values.value, ()=>{
        if(process.client){
            localStorage.setItem('sectionInstance', JSON.stringify(values.value))
        }
    }, {deep:true})

    const setValue = (key:string, value:any) => {
        values.value[key] = value
    }

    return {
        values,
        setValue
    }
})
